@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Source Sans Pro", sans-serif;
}

html {
  scroll-behavior: smooth;
}

/* Loader.css */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full-screen height */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.logo {
  paint-order: stroke fill;
  font-style: italic;
  /* color: #023192; */
  color: white; /* Fill color */
  -webkit-text-stroke: 5px #023192;
}

/* Start Custom css for sidebar navlink */

/* .inverted-border-radius {
    position: relative;
    margin-right: 0px;
    background-color: white;
    border-radius: 8px 0px 0px 8px;
}
   */
/* For top-right inverted effect */
/* .inverted-border-radius::before {
    content: "";
    position: absolute;
    right: 0;
    top: -50px; 
    height: 50px;
    width: 20px;
    background-color: transparent;
    border-bottom-right-radius: 17px;
    box-shadow: 0 30px 0 0 white; 
   
  }  */

/* For bottom-right inverted effect */
/* .inverted-border-radius::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -50px;
    height: 50px;
    width: 20px;
    background-color: transparent;
    border-top-right-radius: 17px;
    box-shadow: 0 -30px 0 0 white; 
   
  }  */

/* Default styles without the inverted-border-radius */
.inverted-border-radius {
  position: relative;
  margin-right: 8px;
  background-color: white;
  border-radius: 8px;
}

/* Only apply the inverted-border-radius effects for larger screens */
@media (min-width: 1024px) {
  .inverted-border-radius {
    /* Include the same styles, as they are specific to larger screens */
    position: relative;
    margin-right: 0px;
    background-color: white;
    border-radius: 8px 0px 0px 8px;
  }

  /* For top-right inverted effect */
  .inverted-border-radius::before {
    content: "";
    position: absolute;
    right: 0;
    top: -50px;
    height: 50px;
    width: 20px;
    background-color: transparent;
    border-bottom-right-radius: 17px;
    box-shadow: 0 30px 0 0 white;
  }

  /* For bottom-right inverted effect */
  .inverted-border-radius::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -50px;
    height: 50px;
    width: 20px;
    background-color: transparent;
    border-top-right-radius: 17px;
    box-shadow: 0 -30px 0 0 white;
  }
}

/* End Custom css for sidebar navlink */
.custom-shadow {
  box-shadow: 0px 0px 8px 5px rgba(166, 158, 158, 0.35);
  -webkit-box-shadow: 0px 0px 8px 5px rgba(166, 158, 158, 0.35);
  -moz-box-shadow: 0px 0px 8px 5px rgba(166, 158, 158, 0.35);
}

.custom-border {
  border-radius: 1.5rem 0.25rem 1.5rem 0.25rem;
}

/* Custom styles for the date picker */
.react-datepicker-wrapper {
  width: 100%;
}

/* .react-datepicker__input-container input {
  padding: 8px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
} */
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  fill: #ffffff !important;
}
.react-datepicker {
  width: 260px !important;
}
.react-datepicker__month-container {
  width: 100% !important;
}

.react-datepicker__header {
  background-color: #fff !important;
}

.react-datepicker__day--selected {
  background-color: #0090ff !important;
  color: white;
}

.Toastify__toast {
  border-radius: 0.25rem !important; /* Decrease border radius */
}

.l {
  color: #023192;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.2s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #0090ff;
}

.o {
  color: #023192;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.4s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #0090ff;
}

.a {
  color: #023192;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.6s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #0090ff;
}
.d {
  color: #023192;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.8s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #0090ff;
}

.i {
  color: #023192;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #0090ff;
}

.n {
  color: #023192;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1.2s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #0090ff;
}

.g {
  color: #023192;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1.4s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #0090ff;
}

.d1 {
  color: #023192;
  opacity: 0;
  animation: pass1 2s ease-in-out infinite;
  animation-delay: 1.6s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #0090ff;
}

.d2 {
  color: #023192;
  opacity: 0;
  animation: pass1 2s ease-in-out infinite;
  animation-delay: 2s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #0090ff;
}

@keyframes pass {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pass1 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
